<template>
  <div class="import-being-processed">
    <global-alert>
      {{ $t("import-being-processed") }}
    </global-alert>
  </div>
</template>

<script>
const GlobalAlert = () => import("@/components/core/global-alert");

export default {
  name: "import-being-processed",
  components: {
    GlobalAlert
  }
};
</script>
