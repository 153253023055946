<template>
  <div class="admin-royalties">
    <portal to="page-call-to-action">
      <ebp-button
        v-text="$t('export-csv')"
        @click="exportCSV"
        :block="isSmallScreen"
        :loading="downloading"
      />
    </portal>
    <!-- Alert when import being processed -->
    <import-being-processed v-if="processing" />

    <advanced-data-table
      action="royalties/get"
      :headers="headers"
      ref="table"
      :limit="50"
      :filters.sync="filters"
    >
      <template #cell(amount)="{item}">
        {{
          formatCurrency(
            item.base_currency_final_amount || item.base_currency_amount,
            item.base_currency.code
          )
        }}
      </template>
      <template #cell(commission)="{item}"> {{ item.commission }}% </template>

      <template #cell(status)="{item}">
        <!-- Use statusVariant from @/mixins/colors.js -->
        <h5 class="m-0">
          <b-badge :variant="statusVariant(item.status)" class="mr-1">{{
            $t(item.status)
          }}</b-badge>
        </h5>
      </template>

      <template #cell(client)="{item}">
        {{ item.user && item.user.name }}
      </template>

      <template #cell(sales_channel)="{item}">
        {{ item.sales_channel && item.sales_channel.name }}
      </template>

      <template #cell(book)="{item}">
        <router-link
          target="_blank"
          :to="{
            name: 'AdminEditBook',
            params: { id: item.book.id }
          }"
        >
          {{ item.book.title }}
        </router-link>
      </template>

      <template #cell(date_of_sale)="{item}">
        {{ formatDate(item.sale_record.date_of_sale) }}
      </template>

      <!-- Actions -->
      <template #actions="{ item }">
        <ebp-button
          outlined
          variant="danger"
          size="sm"
          class="ml-1"
          :loading="removing === item.id"
          @click="remove(item)"
          v-tooltip.top-center="$t('delete')"
        >
          <i class="bx bxs-trash"></i>
        </ebp-button>
      </template>

      <!-- Filters -->
      <template #filters>
        <!-- Status filter -->
        <adt-filter :label="$t('status')">
          <b-form-select
            :options="addAllOption(royaltyStatuses)"
            v-model="filters.status"
            size="sm"
          ></b-form-select>
        </adt-filter>

        <!-- User filter -->
        <adt-filter :label="$t('client')">
          <item-search
            action="users/search"
            label="name"
            trackBy="id"
            v-model="filters.user_id"
          />
        </adt-filter>

        <!-- Book filter -->
        <adt-filter :label="$t('book')">
          <item-search
            action="books/search"
            label="title"
            trackBy="id"
            v-model="filters.book_id"
          />
        </adt-filter>

        <!-- Sales channel filter -->
        <adt-filter :label="$t('sales-channel')">
          <item-search
            action="salesChannels/search"
            label="name"
            trackBy="id"
            v-model="filters.sales_channel_id"
            :filters="{
              parent_only: true
            }"
          />
        </adt-filter>

        <!-- Date range filter -->
        <adt-filter :label="$t('date-range')">
          <date-picker
            v-model="filters.date_range"
            :placeholder="$t('date-range')"
            range
            valueType="format"
          />
        </adt-filter>
      </template>
    </advanced-data-table>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import AdtFilter from "@/components/core/adt-filter";
import royaltySchema from "@/validation-schemas/sale-import";
import ItemSearch from "@/components/core/item-search";
import ImportBeingProcessed from "@/components/import-being-processed";
import royaltyStatuses from "@/fixtures/royalty-statuses";
import DatePicker from "@/components/core/DatePicker";

import truncate from "@/helpers/truncate";
import { mapState } from "vuex";
import { find } from "lodash-es";
import FileDownload from "js-file-download";
import { format, parse } from "date-fns";

export default {
  name: "admin-royalties",
  components: {
    AdvancedDataTable,
    ItemSearch,
    AdtFilter,
    ImportBeingProcessed,
    DatePicker
  },
  data() {
    return {
      downloading: false,
      extra_fields: ["Status", "Commission"],
      royaltyStatuses: royaltyStatuses.map(s => ({
        text: this.$t(s),
        value: s
      })),
      headers: [
        { key: "amount", sortable: true },
        { key: "commission", sortable: true },
        "status",
        "client",
        "book",
        "sales_channel",
        { key: "date_of_sale", sortable: true }
      ],
      editedItem: null,
      saving: false,
      open: false,
      showAddSaleImport: false,
      error: null,
      addTabs: ["import"],
      removing: false,
      filters: {
        user_id: null,
        book_id: null,
        sales_channel_id: null,
        status: null,
        inbound_payment_id: null,
        outbound_payment_id: null,
        date_range: null
      },
      processing: false
    };
  },
  mounted() {
    const channel = this.$pusher.subscribe("import-processed");
    channel.bind("import-processed", ({ royalty }) => {
      this.$router.push({
        name: "ViewSaleRecords",
        query: {
          sales_import_id: royalty.id
        }
      });
    });

    this.$watch(
      () => {
        return this.$refs.table.tableData;
      },
      items => {
        this.processing = !!items.filter(i => i.status === "processing").length;
      }
    );
  },
  destroyed() {
    this.$pusher.unsubscribe("import-processed");
  },
  computed: {
    ...mapState("royalties", ["processorTypes"])
  },
  methods: {
    getProcessorName(code) {
      const type = find(this.processorTypes, o => o.code === code);
      return type ? type.name : "";
    },
    truncate,
    added() {
      this.showAddSaleImport = false;
      this.$refs.table.currentPage = 1;
      this.$refs.table.get();
    },
    async exportCSV() {
      this.downloading = true;
      try {
        const payload = {
          titles: this.filters.book_id,
          user_ids: this.filters.user_id,
          royalties: true,
          sales_channel_id: this.filters.sales_channel_id,
          status: this.filters.status,
          extra_fields: this.extra_fields.join(",")
        };

        if (this.filters.date_range) {
          payload["date_range"] = this.filters.date_range
            .map(d => format(parse(d, "dd-MMM-yyyy", new Date()), "yyyy-MM"))
            .join(",");
        }
        const res = await this.$store.dispatch("reports/exportCSV", payload);

        FileDownload(res, "royalty-records.csv");
      } catch (err) {
        console.error(err);
      }

      this.downloading = false;
    },
    async remove(royalty) {
      if (
        confirm(
          this.$t("remove-message", {
            name: this.formatCurrency(
              royalty.amount,
              royalty.sale_record.currency.code
            )
          })
        )
      ) {
        this.removing = royalty.id;
        try {
          await this.$store.dispatch("royalties/delete", royalty.id);
          this.$refs.table.change("id", royalty.id);
        } catch (err) {
          console.error(err);
        }
        this.removing = false;
      }
    },
    edit(royalty) {
      this.editedItem = royalty;
      this.open = true;
    },
    async save() {
      this.error = null;
      const royalty = this.editedItem;
      const validation = royaltySchema.validate(royalty);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.saving = true;
        try {
          await this.$store.dispatch("royalties/update", royalty);
          this.$refs.table.change("id", royalty.id, royalty);
        } catch (err) {
          this.error = this.errorRes(err);
        }
        this.saving = false;
      }
    }
  }
};
</script>

<style></style>
